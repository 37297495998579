import clsx from 'clsx'
import debug from 'debug'
import { Dispatch, ReactElement, ReactNode, SetStateAction, useState } from 'react'
import { DefaultValues, FieldValues, FormProvider, useForm, UseFormReturn } from 'react-hook-form'

const logger = debug('app:multistep')

// export type StepValues = Record<string, any>
export type StepValues = FieldValues

type SubmitStep<T extends StepValues = StepValues> = (props: {
  nextStepIndex?: number
  nextStepId?: string
}) => Promise<T | null>

interface RenderNavItemDetailsProps<T extends StepValues = StepValues> extends UseFormReturn<FieldValues, object> {
  currStepIndex: number
  stepValues: T
  stepIndex: number
  stepId: string
}

export interface StepComponentProps<T extends StepValues = StepValues> extends UseFormReturn<FieldValues, object> {
  currStepId: string
  setCurrStepId: (stepId: string) => void
  currStepIndex: number
  setCurrStepIndex: Dispatch<SetStateAction<number>>
  stepValues: T
  setStepValues: Dispatch<SetStateAction<T>>
  submitStep: SubmitStep<T>
}

// Can be imported and used in step components like:
// export const StepConfigureUniDetails: StepComponent = (props) => {
export type StepComponent<T extends StepValues = StepValues> = (props: StepComponentProps<T>) => ReactElement

// Can be imported and used to define step config like:
// const stepConfig: StepConfig = [
export type StepConfig<T extends StepValues = StepValues> = {
  id: string // Use string enums with the = 'some-string' rather than default numeric enums
  navTitle?: string
  showInStepNav?: boolean
  showStepNav?: boolean
  StepComponent: StepComponent<T>
}[]

interface MultiStepFlowProps<T extends StepValues = StepValues> {
  stepConfig: StepConfig<T>
  initialStepValues?: T
  initialStepIndex?: number
  title?: string
  description?: string
  breadCrumb?: string
  // Will automatically be hidden if there is only one step, but can be forced hidden with this
  hideStepNav?: boolean
  // Don't use Vendia purple bg, more generic white
  isWhiteLabel?: boolean
  renderNavItemDetails?: (props: RenderNavItemDetailsProps<T>) => ReactNode
}

function MultiStepFlow<T extends StepValues = StepValues>({
  stepConfig,
  initialStepValues = {} as T,
  hideStepNav = false,
  isWhiteLabel = false,
  initialStepIndex = 0,
  title,
  description,
  breadCrumb,
  renderNavItemDetails,
}: MultiStepFlowProps<T>) {
  // We also spread formMethods into each step component
  const formMethods = useForm({
    mode: 'onBlur',
    delayError: 1000,
    shouldFocusError: true,
    defaultValues: initialStepValues as DefaultValues<T>,
  })
  const { trigger, getValues, handleSubmit } = formMethods

  // TODO: store stepId state in query param and find stepIndex from that
  //   if URL has step other then first step on full page load, disregard and use first step instead
  //   This way, we get broweer back/forward in wizards but full page load will reset to first step because
  //   we're not storing state in local storage or anything yet and typically needs to be built up from first step
  const [currStepIndex, setCurrStepIndex] = useState(initialStepIndex)
  const [stepValues, setStepValues] = useState(initialStepValues)
  const currStep = stepConfig[currStepIndex]
  const currStepId = currStep.id

  const showStepNav = stepConfig.length > 1 && !hideStepNav && currStep.showStepNav !== false

  function setCurrStepId(stepId: string) {
    const index = stepConfig.findIndex((step) => step.id === stepId)
    setCurrStepIndex(index)
  }

  // Returns new stepValues or null if form is invalid
  // User cannot move FORWARD until errors are sorted for current page
  const submitStep: SubmitStep<T> = async ({ nextStepIndex, nextStepId }) => {
    if (nextStepId && nextStepIndex) {
      throw new Error('Specify either nextStepId or nextStepIndex')
    }
    if (nextStepId === undefined && nextStepIndex === undefined) {
      throw new Error('Specify either nextStepId or nextStepIndex')
    }
    if (nextStepId) {
      nextStepIndex = stepConfig.findIndex((step) => step.id === nextStepId)
    }
    if (nextStepIndex === undefined) {
      throw new Error('nextStepIndex not found')
    }

    // Validate current step
    const formIsValid = await trigger()
    if (formIsValid === false) {
      logger('Form errors detected')

      // If trying to advance to next step or submitting form, but staying on same step...
      if (nextStepIndex >= currStepIndex || nextStepId === currStepId) {
        // Exit and deal with validation errors on this page
        logger('Cannot advance until form is valid')
        return null
      }
    }

    // Merge current step values into state
    //  (But first remove formValues where value is undefined – a form element may have been unmounted from the
    //  page prior to submission or validation so it will end up as undefined in the formValues object)
    const formValues = getValues()
    Object.keys(formValues).forEach((key) => formValues[key] === undefined && delete formValues[key])
    const updatedStepValues = { ...stepValues, ...formValues }
    logger(`latest stepValues:`, updatedStepValues)
    setStepValues(updatedStepValues)
    setCurrStepIndex(nextStepIndex)

    // Return new stepValues synchronously in case there is logic immediately
    // following this that needs them (as react setState is async)
    return updatedStepValues
  }

  const { StepComponent } = stepConfig[currStepIndex]

  const onSubmit = () => {
    /* no-op */
  }
  const onError = () => {
    /* no-op */
  }

  // Some steps are like sub-steps (pre-steps, post-steps, whatever) - we want to step through them like normal,
  // but not muck up the top stepper nav with extra steps. So we filter them out here.
  const filteredStepConfigForNav = stepConfig.filter((s) => s.showInStepNav !== false)
  // When a step has showInStepNav===false, we want to remain on the same step index for consecutive steps
  const currentNavStepIndex = stepConfig.reduce((acc, curr, stepIndex) => {
    if (curr.showInStepNav === false && stepIndex <= currStepIndex) {
      return acc - 1
    }
    return acc
  }, currStepIndex)

  const wrapperClasses = clsx('flex w-full flex-1 flex-col', {
    // 'from-primaryCore to-purple-1300  bg-gradient-to-r': !isWhiteLabel,
    // 'bg-gray-200': isEmbedded,
  })

  return (
    <div className={wrapperClasses}>
      <div className={'flex w-full flex-1 flex-col items-center justify-center'}>
        <div
          className={`mb-6 mt-2 flex w-full max-w-md items-center justify-evenly gap-1 font-bold text-white ${
            !showStepNav ? '!mb-0 hidden' : ''
          }`}
        >
          {filteredStepConfigForNav.map(({ navTitle, showInStepNav }, stepIndex) => {
            if (showInStepNav === false) {
              return null
            }
            return (
              <div
                key={stepIndex}
                className={clsx({
                  ['h-4 w-full first:rounded-l-full last:rounded-r-full']: true,
                  ['bg-neutral-3']: stepIndex > currentNavStepIndex,
                  ['bg-violet-800']: stepIndex <= currentNavStepIndex,
                })}
              />
            )
          })}
        </div>
        <FormProvider {...formMethods}>
          <form
            className={'flex w-full flex-1 flex-col items-center justify-center'}
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            {/* @ts-ignore can't figure this one out lol */}
            <StepComponent
              {...formMethods}
              currStepId={currStepId}
              setCurrStepId={setCurrStepId}
              currStepIndex={currStepIndex}
              setCurrStepIndex={setCurrStepIndex}
              stepValues={stepValues}
              setStepValues={setStepValues}
              submitStep={submitStep}
            />
          </form>
        </FormProvider>
      </div>
      {/* <DevTool control={formMethods.control} /> */}
    </div>
  )
}

/**
  Use these layout components like so:
  return (
    <StepWrapper>
      <ScrollableStepContent>
        { Your step content here }
      </ScrollableStepContent>
      <StepButtonsWrapper>
        {/* Example buttons  *\/}
        <Button kind='tertiary' onClick={() => navigate('/')}>
          Cancel
        </Button>
        <Button kind='primary' onClick={() => submitStep({ ... })}>
          Next
        </Button>
      </StepButtonsWrapper>
    </StepWrapper>
  )
*/
export const StepWrapper = ({ children }: { children: ReactNode }) => {
  return <div className={`flex w-full flex-1 flex-col items-center justify-center`}>{children}</div>
}
/*
The scrollable, fixed-height "portal" thing is a little tricky, but this thing works pretty well.
Keeps the header/footer in place, and the content scrollable and hides the corners of the scrollbar.

You'll have to add 'w-full' and 'p-6' to the parent div placed within this component - didn't
add that here to make it possible to add full width/height dividers if needed, etc.

Single column layout, just use the ScrollableStepContent component:
  <ScrollableStepContent>{entityDetailsColumn}</ScrollableStepContent>

Two column layout, use this pattern and tweak column flex/width as needed
  <div className='flex flex-1 gap-6 w-full'>
    <div className='w-1/4 flex flex-1'>
      <ScrollableStepContent>{entityNavColumn}</ScrollableStepContent>
    </div>
    <div className='w-3/4 flex'>
      <ScrollableStepContent>{entityDetailsColumn}</ScrollableStepContent>
    </div>
  </div>
*/
export const ScrollableStepContent = ({
  children,
  className,
  inset = false,
  verticallyCentered = false,
}: {
  children: ReactNode
  className?: string
  inset?: boolean
  verticallyCentered?: boolean
}) => (
  <div className={clsx('relative flex w-full flex-1 flex-col', className)}>
    {/* This absolute positioning trick extends div to parent borders, and allows to hide content regardless
      of parent height (otherwise you generally have to set a fixed height to get overflow-scroll/auto to work correctly)*/}
    <div
      className={clsx(
        'absolute bottom-0 left-0 right-0 top-0 flex w-full flex-1 overflow-hidden',
        inset ? 'bg-uibg-1' : 'bg-white',
      )}
    >
      <div
        className={clsx(
          'flex w-full justify-center overflow-y-auto pb-8',
          inset ? 'bg-uibg-1 shadow-uibg-7 shadow-inner-lg border-uibg-7 border-y' : 'bg-white',
        )}
      >
        {verticallyCentered ? <div className='grid w-full place-items-center px-4 pb-16'>{children}</div> : children}
      </div>
    </div>
  </div>
)

export const StepButtonsWrapper = ({ children }: { children: ReactNode }) => (
  <div className='h-22 border-neutral-3 flex w-full items-start justify-end border-t p-4 py-6'>
    <div className='flex space-x-2'>{children}</div>
  </div>
)
interface StepHeaderProps {
  title: ReactNode
  large?: boolean
  centered?: boolean
  hasMarginY?: boolean
  description?: ReactNode
  actions?: ReactNode
  className?: string
}
export const StepContentHeader = ({
  title,
  description,
  actions,
  large = false,
  centered = false,
  hasMarginY = false,
  className = '',
}: StepHeaderProps) => {
  return (
    <div
      className={clsx(
        `mb-2 flex w-full items-center`,
        hasMarginY && 'mb-8 mt-6',
        centered && 'justify-center',
        !centered && 'justify-between',
        className,
      )}
    >
      <div className={clsx('flex max-w-4xl flex-1 flex-col justify-center', !centered && 'max-w-4xl')}>
        <h2
          className={clsx(
            'font-bold',
            description ? '!mb-2' : '!mb-0',
            large ? 'text-4xl' : 'text-2xl',
            centered ? 'text-center' : 'text-left',
          )}
        >
          {title}
        </h2>
        {description && (
          <div
            className={clsx(
              'text-neutral-8 mb-2 text-balance',
              centered && 'text-center',
              large ? 'text-base' : 'text-sm',
            )}
          >
            {description}
          </div>
        )}
      </div>
      {actions}
    </div>
  )
}

export const StepContentHeaderMega = ({ title }: { title: ReactNode }) => {
  return (
    <div className='mb-2 flex w-full items-center justify-center border-b border-gray-200'>
      <h1 className='m-0 mb-5 py-4 text-5xl font-semibold'>{title}</h1>
    </div>
  )
}
export default MultiStepFlow
