import { colors } from './colors.js'
import { iconSizes } from './icons.js'

/** **
Global CSS variables for use in CSS and JS

## CSS usage:

  ```css
  backgound: $varName
  ```

## JS usage

  ```js
  import variables from '../'

  const { blue } = variables
  ```
*** */

const baseValue = 1
const unit = 'rem'
const baseFontSize = baseValue * 1.6 + unit

export const variables = {
  textSelection: '#80cbbf',
  // -- Colors
  danger: colors.error6,
  dangerHover: colors.error4,

  ...colors,
  // Overide primary TODO fix in figma
  primary: colors.neutral11,
  primaryHover: colors.neutral9,

  ...iconSizes,

  inputBorderRadius: '6px',
  inputBorderColor: colors.neutral5,
  // -- Icon sizes
  iconDefault: '36px',
  // -- Navigation settings
  navHeight: '60px',
  // -- Breadcrumb settings
  breadCrumbHeight: '40px',
  // -- Layout settings
  padding: '24px',
  // -- Fonts
  fontSize: baseFontSize,
  fontSizeTiny: formatFont(1.2),
  fontSizeSmall: formatFont(1.4),
  fontSizeNormal: baseFontSize,
  fontSizeBig: formatFont(1.8),
  fontSizeH1: formatFont(3.0),
  fontSizeH2: formatFont(2.15),
  fontSizeH3: formatFont(1.7),
  fontSizeH4: formatFont(1.25),
  fontSizeH5: baseFontSize,
  fontSizeH6: formatFont(0.85),
  fontWeightThin: 300,
  fontWeightNormal: 400,
  fontWeightSemiBold: 500,
  fontWeightBold: 700,
  // -- Indexes
  zIndexHighest: 300,
  zIndexHigher: 200,
  zIndexHigh: 100,
  zIndexNormal: 1,
  zIndexLow: -100,
  zIndexLower: -200,
}

/*
Refs:
  https://github.com/amzn/style-dictionary + https://github.com/amzn/style-dictionary/tree/master/examples/basic
  https://www.lightningdesignsystem.com/design-tokens/
  https://polaris.shopify.com/design/colors#navigation
  https://github.com/salesforce-ux/theo#supported-categories
  https://diez.org/getting-started/the-basics.html
 */

function formatFont(modifier) {
  return modifier * baseValue + unit
}
