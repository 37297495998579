/* eslint jsx-a11y/click-events-have-key-events:0, jsx-a11y/no-static-element-interactions: 0 */
import clsx from 'clsx'
import React, { CSSProperties, MouseEventHandler } from 'react'
import { assert } from 'src/utils/assert'
import { variables } from 'src/utils/styles/variables'

import sprite from '../../assets/icons/sprite'
import addSVGtoDOM from './icon.addSvgToDom'
import styles from './icon.module.css'

export type IconProps = {
  name: string
  /** Custom CSS Classes */
  className?: string
  /** Custom CSS Classes */
  style?: CSSProperties
  /** Size of Icon. Takes number or pixel value. Example size={30} */
  size?: 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | number | string
  /** Fill color override from default. Hex value */
  fill?: string
  /** Fill color override from default. Hex value */
  stroke?: string
  /** if true the icon will spin */
  isSpinning?: boolean
  /** Custom on click function */
  onClick?: MouseEventHandler
  /** Alt text title */
  title?: string
  /** Optional inline SVG children */
  children?: React.ReactElement
} & React.HTMLAttributes<HTMLSpanElement>

/**
 * See all icons at <a href="https://vendia-ux.netlify.app/icons">Icons list</a>
 */
const Icon = ({
  children,
  className,
  size = variables.iconDefault,
  onClick,
  title,
  style,
  fill,
  stroke,
  isSpinning,
  name,
  ...rest
}: IconProps) => {
  const sizeClass = styles[`${size}`]
  const classes = clsx('component-icon', styles.wrapper, sizeClass, className)

  // If size is passed as string, must include 'px' or other unit size at the end or breaks on firefox
  // Assert: if string and string is ONLY digits, throw an error
  assert(typeof size === 'string' ? !/^\d+$/.test(size) : true, 'size must be a number or string **with unit**')

  const svgClasses = clsx(styles.icon, {
    [styles.spinning]: isSpinning,
    [styles.hasClick]: onClick,
  })

  const customSize = {
    height: size,
    width: size,
  }

  const fillStyles = fill ? { fill: fill } : {}
  const strokeStyles = stroke ? { stroke: stroke } : {}

  const platformPrefix = ''

  let iconContents = <use xlinkHref={`#${platformPrefix}${name}`} />
  /* If inline SVG used render */
  if (children && (children.type === 'g' || children.type === 'svg' || children.type === 'circle')) {
    iconContents = children
  }

  const inlinedStyles = {
    ...customSize,
    ...fillStyles,
    ...strokeStyles,
  } as unknown as CSSProperties

  return (
    <span
      title={title as string}
      className={classes}
      style={style as CSSProperties}
      onClick={onClick as MouseEventHandler}
      data-testid='icon-component'
      {...rest}
    >
      <svg style={inlinedStyles} className={svgClasses}>
        {iconContents}
      </svg>
    </span>
  )
}

Icon.loadSprite = () => {
  addSVGtoDOM(null, sprite)
}

export default Icon
