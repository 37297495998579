import {
  captureException as sentryCaptureException,
  configureScope as sentryConfigureScope,
  init as sentryInit,
  setUser as sentrySetUser,
} from '@sentry/browser'
import { CaptureConsole } from '@sentry/integrations'
import { BrowserTracing } from '@sentry/tracing'

// Address https://sentry.io/organizations/vendia/issues/3897052476/
let initialized = false
export function initSentry({ dsn = import.meta.env.NEXT_PUBLIC_SENTRY }) {
  if (initialized) return
  initialized = true

  // Disable Sentry in development (but make sure we keep it active during CI/Cypress tests)
  if (import.meta.env.DEV) {
    return
  }

  const sampleRate = import.meta.env.NEXT_PUBLIC_VENDIA_ENV === 'prod' ? 1 : 0.01

  sentryInit({
    dsn: dsn,
    integrations: [
      // @ts-ignore works fine
      new BrowserTracing(),
      // @ts-ignore works fine
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    // TODO in future:
    // release: `${appName}@${commitHash}-${env},
    // Always capture replays when an error is detected
    // replaysOnErrorSampleRate: 1.0,
    sampleRate,
    tracesSampleRate: sampleRate,
  })

  // Get current build context and set to sentry context
  fetch('/build.json')
    .then((res) => res.json())
    .then((data) => {
      const info = data || {}
      if (info) {
        sentryConfigureScope((scope) => {
          // Set git commit
          if (info.commit) {
            scope.setTag('commit', info.commit)
          }
          // Set github action build ID
          if (info.buildId) {
            scope.setTag('buildId', info.buildId)
          }
        })
      }
      // Attach onerror listener for missing chunks
      window.onerror = (msg, url, line, col, error) => {
        // @ts-ignore maybe error comparison to string works here, not sure but leaving it as is
        if (msg === 'SyntaxError' && error === `Unexpected token '<'`) {
          captureException({
            message: `Chunk missing in ${info.commit}!`,
            commit: info.commit,
            buildId: info.buildId,
          })
        }
      }
    })
    .catch((e) => e) // swallow error
}

export function captureException(e: any) {
  // Fix https://bit.ly/3FeNIwF
  const error = e instanceof Error ? e : new Error(JSON.stringify(e))
  // No sentry in local dev
  if (import.meta.env.DEV) {
    return
  }
  sentryCaptureException(error)
}

export function setUser(id: string) {
  // No sentry in local dev
  if (import.meta.env.DEV) {
    return
  }
  sentrySetUser({ id })
}
