export const iconSizes = {
  /* extra extra small */
  xxs: 12,
  /* extra small */
  xs: 16,
  /* small */
  s: 24,
  /* medium */
  m: 36,
  /* large */
  l: 48,
  /* extra large */
  xl: 96,
  /* extra extra large */
  xxl: 124,
}
