export default {
  "link": "#156a95",
  "primaryCore": "#26045D",
  "primaryCoreLight": "#512894",
  "secondaryCore": "#20349E",
  "secondaryCoreLight": "#3347ad",
  "tertiaryCore": "#4F047B",
  "purple50": "#F4DEFF",
  "purple100": "#EED3FC",
  "purple200": "#E4BEF7",
  "purple300": "#DFB3F5",
  "purple400": "#DAA9F2",
  "purple500": "#D59EF0",
  "purple600": "#CB89EB",
  "purple700": "#BF75E3",
  "purple800": "#B263D8",
  "purple900": "#A551CE",
  "purple1000": "#973FC3",
  "purple1100": "#8A2DB8",
  "purple1200": "#8424B3",
  "purple1300": "#692f7a",
  "uibg0": "#F9FAFD",
  "uibg1": "#F3F5FB",
  "uibg2": "#EEF1FA",
  "uibg3": "#E7E9F8",
  "uibg4": "#E0E2F6",
  "uibg5": "#D9DAF4",
  "uibg6": "#D2D3F2",
  "uibg7": "#CCCBF0",
  "uibg8": "#C5C4EE",
  "neutral0": "#f8f9fa",
  "neutral1": "#f1f3f5",
  "neutral2": "#edeef2",
  "neutral3": "#dbdee5",
  "neutral4": "#cacdd9",
  "neutral5": "#b9bdcc",
  "neutral6": "#979eb3",
  "neutral7": "#76809b",
  "neutral8": "#65718f",
  "neutral9": "#34486c",
  "neutral10": "#223b61",
  "neutral11": "#0b2e56",
  "neutral12": "#001b40",
  "neutral13": "#00052e",
  "error0": "#ffe8e4",
  "error1": "#ffd1ca",
  "error2": "#fdb9b0",
  "error3": "#f9a297",
  "error4": "#f38b7f",
  "error5": "#ec7267",
  "error6": "#e45850",
  "error7": "#db3a3a",
  "error8": "#c4212a",
  "error9": "#ae001b",
  "error10": "#97000c",
  "error11": "#820000",
  "error12": "#6d0000",
  "error13": "#590000",
  "success0": "#E0FFE0",
  "success1": "#CFF8CF",
  "success2": "#AFECAF",
  "success3": "#9FE69F",
  "success4": "#8EE08E",
  "success5": "#7EDA7E",
  "success6": "#6ED46E",
  "success7": "#5ECE5E",
  "success8": "#46BE46",
  "success9": "#36AB38",
  "success10": "#2EA130",
  "success11": "#1F8E22",
  "success12": "#0F7B13",
  "success13": "#006805",
  "information0": "#edf7fd",
  "information1": "#dbeffb",
  "information2": "#c8e7fa",
  "information3": "#b4dff8",
  "information4": "#9fd7f6",
  "information5": "#89cff4",
  "information6": "#70c7f2",
  "information7": "#51bff0",
  "information8": "#26a3d2",
  "information9": "#0087b5",
  "information10": "#006d99",
  "information11": "#00537e",
  "information12": "#003b64",
  "information13": "#00254a",
  "warning0": "#fff2e1",
  "warning1": "#ffe5c3",
  "warning2": "#ffd9a5",
  "warning3": "#ffcc88",
  "warning4": "#ffc06a",
  "warning5": "#ffb34a",
  "warning6": "#ffa722",
  "warning7": "#e18e00",
  "warning8": "#c47600",
  "warning9": "#a75e00",
  "warning10": "#8b4800",
  "warning11": "#713100",
  "warning12": "#5a1b00",
  "warning13": "#460300"
}