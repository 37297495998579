import debug from 'debug'

const env = import.meta.env.NEXT_PUBLIC_VENDIA_ENV

const isBrowser = typeof window !== 'undefined'
const isLocalHost = isBrowser && window.location.hostname === 'localhost'
const isProdDomain = isBrowser && window.location.hostname === 'share.vendia.net'
/* Match https://WHATEVER--vendia-app.netlify.app/ */
const isPreview = isBrowser && !!window.location.origin.match(/:\/\/(.*)--vendia-app\.netlify\.app$/)
const isProd = isProdDomain && env === 'prod'
const isStaging = env === 'staging'
// @TODO make dev truly dev
const isDev = !isProd

const gaTrackingId = isProd ? import.meta.env.NEXT_PUBLIC_GANALYTICS_PROD : import.meta.env.NEXT_PUBLIC_GANALYTICS_DEV
const hubspotId = isProd ? import.meta.env.NEXT_PUBLIC_HUBSPOT_ID_PROD : import.meta.env.NEXT_PUBLIC_HUBSPOT_ID_DEV
const feedbackEndpoint = isDev
  ? 'https://zpy8d5w7ei.execute-api.us-east-1.amazonaws.com/dev/entry'
  : 'https://4cia6q5l75.execute-api.us-east-1.amazonaws.com/prod/entry'

// @TODO remove fallback URI after schema migrations
const FALLBACK_UI_URL = 'https://6164bf18d83873135684055e--vendia-app.netlify.app'

/* On local dev, enable all DEBUG output */
if (isLocalHost) {
  /* // Enable all logs by default
  debug.enable('*')
  /* @ts-ignore dynamically adding to window */
  window.__debug = debug
  // Enable specific debug logs __debug.enable("-engine*,-socket*")
  // Disable specific debug logs __debug.disable("-engine*,-socket*")
}

export default {
  env,
  isBrowser,
  isLocalHost,
  isDev,
  isProd,
  isStaging,
  isPreview,
  // Analytics settings
  gaTrackingId,
  hubspotId,
  // User feedback endpoint
  feedbackEndpoint,
  cognito: {
    USER_POOL_REGION: import.meta.env.NEXT_PUBLIC_USER_POOL_REGION,
    USER_POOL_ID: import.meta.env.NEXT_PUBLIC_USER_POOL_ID,
    USER_POOL_CLIENT_ID: import.meta.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID,
    USER_POOL_FEDERATED_CLIENT_ID: import.meta.env.NEXT_PUBLIC_USER_POOL_FEDERATED_CLIENT_ID,
    IDENTITY_POOL_ID: import.meta.env.NEXT_PUBLIC_IDENTITY_POOL_ID,
    FEDERATED_IDENTITY_POOL_ID: import.meta.env.NEXT_PUBLIC_FEDERATED_IDENTITY_POOL_ID,
  },
  pinpoint: {
    appId: import.meta.env.NEXT_PUBLIC_PINPOINT_APP_ID,
    region: import.meta.env.NEXT_PUBLIC_PINPOINT_REGION,
    lambdaArn: import.meta.env.NEXT_PUBLIC_PINPOINT_LAMBA_ARN,
    lambdaRegion: import.meta.env.NEXT_PUBLIC_PINPOINT_REGION,
  },
  shareApi: import.meta.env.NEXT_PUBLIC_SHARE_API_ENDPOINT,
  domainApi: import.meta.env.NEXT_PUBLIC_DOMAIN_API_ENDPOINT,
  // @TODO remove this in 2 weeks 2021-10-12. Needed for backwards compat support
  FALLBACK_UI_URL,
}
