import variables from '../../assets/styles/variables.js'

// Used for CSS modules postCSS tokens
export const colors = Object.keys(variables).reduce((acc, curr) => {
  const value = variables[curr]
  if (value.startsWith('#')) {
    acc[curr] = value
  }
  return acc
}, {})

// Used to add same color palette to tailwind
// Accessed like className="bg-brandGreen-1"
export const tailwindPalette = Object.entries(colors).reduce((acc, [colorKey, colorHex]) => {
  // Should match anything with numeric suffix like "brandGreen1"
  const numberMatch = colorKey.match(/(\d)+$/)

  // If it's not a sequence of numeric values, just add the color to the palette, as is
  if (numberMatch === null) {
    acc[colorKey] = colorHex
  } else {
    const number = numberMatch[0]
    const newKey = colorKey.replace(/(\d)+$/, '')
    if (acc[newKey]) {
      acc[newKey][number] = colorHex
    } else {
      acc[newKey] = { [number]: colorHex }
    }
  }
  return acc
}, {})
